

import jsonData from './JSON/homepage.json';

// NAVBAR
export const NAV_LEFT_TITLE = jsonData.NAVBAR.NAV_LEFT_TITLE;
export const NAV_ITEMS = jsonData.NAVBAR.NAV_ITEMS;
export const NAV_BUTTONS = jsonData.NAVBAR.NAV_BUTTONS;

// PROJECTS
export const PROJECT_LIST = jsonData.PROJECTS;

// Technologies
export const TECHNOLOGIES = jsonData.Technologies;

export const TECHNOLOGY_PAGE = jsonData.TECHNOLOGY_PAGE;