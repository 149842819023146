
import Navbar from '../Components/HomePageComponents/Navbar';
import emailjs from 'emailjs-com';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';


function ContactPage () {
    
    const emailRef = useRef("");
    const messageRef = useRef("");
    const form = useRef();
    const navigate = useNavigate();
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);

    useEffect(() => {
        emailjs.init("user_yourEmailjsUserId");
    })

    const handleSubmission = (e) => {
        console.log(emailRef.current.value);
        console.log(messageRef.current.value);
        setShowSuccessMsg(false);
        setShowErrorMsg(false);
        e.preventDefault();
        // e.preventDefault();
        const templateParams = {
            to_name: "Robin Nguyen",
            from_name: emailRef.current.value,
            custom_text: messageRef.current.value,
          };
        //   setShowErrorMsg(true);
        //     setShowSuccessMsg(false);
        console.log("Setting Success msg to True");
        setShowSuccessMsg(true);
        setShowErrorMsg(false);

        const textFieldValue = emailRef.current.value;

        // Regular expression pattern to match a basic email format
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Use the test method of the regular expression
        const isValidEmail = emailPattern.test(textFieldValue);

        // Check the result
        if (isValidEmail) {
            console.log("The text field contains a valid email format.");
            emailjs.send('service_kj981ar', 'template_o8djot8', templateParams, 'e4I9UZgflH40B6mMg')
            .then((result) => {
                // console.log(result.text);
                setShowSuccessMsg(true);
            setShowErrorMsg(false);
                setTimeout(function() {
                    // Code to be executed after the delay
                    navigate('/');
                }, 5000);
                
            }, (error) => {
                // console.log(error.text);
                setShowErrorMsg(true);
                showSuccessMsg(false);
            });
        } else {
            console.log("The text field does not contain a valid email format.");
            setShowErrorMsg(true);
            setShowSuccessMsg(false);
        }
        

    }

    return (
        <div style={{ width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <Navbar />
        <h1 className="portfolio-form-header">Let's get in touch!</h1>
        <div className="portfolio-formblock w-form">
            <form
            onSubmit={handleSubmission}
            id="email-form"
            name="from_name"
            data-name="Email Form"
            method="get"
            className="portoflio-form"
            data-wf-page-id="65482eca5cd5e6fe9f5e7923"
            data-wf-element-id="6bab421e-ad42-fac6-31f0-a4aa796cb9e8"
            >
            <label htmlFor="name" className="portfolio-field-lbl">
                Email
            </label>
            <input
                ref={emailRef}
                type="text"
                className="portfolio-textfield w-input"
                maxLength={256}
                name="custom_text"
                data-name="Name"
                placeholder="Please enter your email address"
                id="name"
            />
            <label htmlFor="email" className="portfolio-field-lbl">
                Message
            </label>
            <textarea
            ref={messageRef}
                placeholder="Feel free to get in touch and write a quick message here."
                maxLength={5000}
                id="field"
                name="field"
                data-name="Field"
                className="portoflio-textarea w-input"
                defaultValue={""}
            />
            <button 
                className={showSuccessMsg ? "portfolio-submit-disabled" : "portfolio-submit"}
                disabled={showSuccessMsg ? true : false}
            >Submit</button>
            </form>
            {showSuccessMsg ? <div style={{
                display: "block",
                marginTop: "10px",
                padding: "20px",
                textAlign: "center",
                backgroundColor: "#dddddd"
            }}>
            <div>Thank you! Your submission has been received! Redirecting to Home page...</div>
            </div> : showErrorMsg ? <div style={{display: "block",
    marginTop: "10px",
    padding: "10px",
    backgroundColor: "#ffdede"}}>
            <div>Oops! Something went wrong while submitting the form.</div>
            </div> : null}
        </div>
        </div>
    )
}

export default ContactPage;