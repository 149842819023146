import HomePage from './Pages/HomePage';
import AboutPage from './Pages/AboutPage';
import ProjectPage from './Pages/ProjectPage';
import ContactPage from './Pages/ContactPage';
import TechnologyPage from './Pages/TechnologyPage';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import './static/css/normalize.css';
import './static/css/portfolio.css';
import './static/css/webflow.css';

function App() {
  return (
    <Router>
        <div className="App">
          <div className="portfolio-body">
            <div className="project-page-container">
            <Routes>
              <Route path="/" element={<HomePage />}/>
              <Route path="/about" exact element={<AboutPage />}/>
              <Route path="/project" exact element={<ProjectPage /> }/>
              <Route path="/contact" exact element={<ContactPage /> }/>
              <Route path="/technology" exact element={<TechnologyPage />} />
            </Routes>
            </div>
          </div>
        </div>
      </Router>

  );
}

export default App;
