import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../Components/HomePageComponents/Navbar';
import {useSearchParams} from 'react-router-dom';
import { PROJECT_LIST } from '../static/constants';

import BobaRealmSVG from '../static/images/BobaRealmThumbnailSVG.svg';
import PathfinderIcon from '../static/images/PathfinderIcon.svg';
import HeartBakerJPG from '../static/images/HeartBakerJPG.jpg';

import LinkSVG from '../static/images/LinkSVG.svg';
import GithubSVG from '../static/images/GithubSVG.svg';

function BobaRealmProject() {

  const [searchParams, setSearchParams] = useSearchParams();
  const [projectIndex, setProjectIndex] = useState(-1);
  const [projectInfo, setProjectInfo] = useState({});
    useEffect(() => {
      // console.log(searchParams);
      // console.log(searchParams.get("name"));
      let projectName = searchParams.get("name");
      let index = projectName === "bobarealm" ? 0 : projectName === "pathfindingvisualizer" ? 1 : projectName === 'heartbaker' ? 2 : -1;
      setProjectIndex(index);
      setProjectInfo(PROJECT_LIST[index]);
      console.log(PROJECT_LIST[index]);
    }, [searchParams]);

    return (
      <div style={{width: '100%'}}>
            <Navbar />
            <h1 className="project-page-title">{projectInfo && projectInfo.title}</h1>
            <div className="project-block">
              <h1 className="project-demo-header">Demo:</h1>
              <div className="demo-container">
                <div className="w-embed-youtubevideo demo-video" />
              </div>
            </div>


            <div className="project-block">
  <h3 className="portfolio-project-heading">Pathfinding Visualizer</h3>
  <div className="portfolio-project-container">
    <div className="portfolio-project-image-div">
      <img
        src={projectInfo.iconIndex === 0 ? BobaRealmSVG : projectInfo.iconIndex === 1 ? PathfinderIcon : projectInfo.iconIndex === 2 ? HeartBakerJPG : ""}
        loading="lazy"
        alt=""
        className="portfolio-project-image"
      />
    </div>
    <div className="portfolio-project-info-div">
      <h3 className="project-title">Details:</h3>

      {projectInfo.detailList && projectInfo.detailList.map((projectSubitem, subIndex) => (
        <div key={subIndex} className="project-info-block">
          <div className="projectlbl">{projectSubitem[0]}:</div>
            <div className="projectlbl-descriptor">
                  {projectSubitem[1]}
              <br />
          </div>
      </div>
      ))}
      <div className="portfolio-info-links">
        {projectInfo && projectInfo.Links && projectInfo.Links.map((projectItem, index) => (
          <a target="_blank" key={index} href={projectItem.link} className="portfolio-project-btn">
          <img
            src={projectItem.icon === "link" ? LinkSVG : GithubSVG}
            loading="lazy"
            alt=""
            className="portfolio-btn-icon"
          />
          <div className="portfolio-btn-txt">{projectItem.buttonTitle}</div>
        </a>
        ))}
      </div>
      <div className="project-status-div">
        <div className="project-status-txt">Status: {projectInfo.Status}</div>
      </div>
    </div>
  </div>
</div>
</div>

    )
}

export default BobaRealmProject;