import { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../Components/HomePageComponents/Navbar';

function AboutPage() {
    const { state } = useLocation();
    const aboutRef = useRef();
    const professionalRef = useRef();
    const extracurricularRef = useRef();

    const indexDictionary = {
        "default": aboutRef,
        "professional": professionalRef,
        "extracurricular": extracurricularRef 
      };
    useEffect(() => {
        if(state) {
            const targetElement = indexDictionary[state];
            console.log(targetElement);
            if (targetElement) {
                window.scrollTo(0, 0);
                targetElement.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [state])
    return (
        <div style={{width: '100%'}}>
        <Navbar />
        <div ref={aboutRef} className="portfolio-page-about-section">
            <h1 className="portfolio-page-header">About</h1>
            <div className="portfolio-about-text">
            Hello! My name is Robin.&nbsp;I&nbsp;am a software engineer from
            Sacramento, California. I graduated from UC&nbsp;San Diego in Spring 2022,
            where I majored in Computer Science and Mathematics. Since then, I have
            worked full-time at Acumen, LLC on the software development team based in
            Burlingame, California where I&nbsp;currently reside. I develop full-stack
            web applications using various technologies such as Django, React, and
            other relevant technologies used by professionals today. I am always
            trying to find ways to improve as a software developer and as an overall
            professional.
            </div>
        </div>
        <div ref={professionalRef} className="portfolio-page-tech-section">
            <div className="portfolio-page-tech-div">
            <h1 className="portfolio-page-header-tech">Professional Experience</h1>
            <div className="portfolio-about-text">
                My only professional role to date is my current role at Acumen, LLC
                where I&nbsp;have worked on the software development team since October
                2022. Here, I develop the frontend user controls, the backend business
                service, windows service code for various projects, and develop
                necessary database code (stored procedures, table/view creation,
                functions, etc).
                <br />
                <br />I collaborate with other members on the software development team,
                with our quality assurance team, with our product management team, and
                with our upper management to produce high quality, enterprise-level code
                for our clients. <br />
            </div>
            </div>
        </div>
        <div ref={extracurricularRef} className="portfolio-page-tech-section">
            <div className="portfolio-page-tech-div">
            <h1 className="portfolio-page-header-tech">
                Extracurriculars
            </h1>
            <div className="portfolio-about-sub-text">
                I've been developing full stack applications with React and Django ever
                since I attended HackSC 2018, where I learned about the latest
                technologies and began my full stack development journey. Since then,
                I've learned a handful of other languages (Node.js, Docker, AWS, etc.)
                and I&nbsp;am still continuing my lifelong journey to learn every single
                day!
                <br />
                <br />
                One of my favorite things to do is visit my local coffee shop and work
                on my craft as a programmer and problem solver. Whether I am learning a
                new skill on Udemy or Youtube, cranking out LeetCode problems, or
                working on personal projects, I always try my best to improve each and
                every day.
                <br />
            </div>
            </div>
        </div>
        <div className="portfolio-tech-stack-about">
            <h1 className="technology-subheader">Languages</h1>
            <div className="technology-list">
            <div className="technology-item">
                <div className="technology-icon">
                <img
                    src="images/python-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="technology-icon-img"
                />
                </div>
                <div className="technology-title">Python</div>
            </div>
            <div className="technology-item">
                <div className="technology-icon">
                <img
                    src="images/js-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="portfolio-page-tech-im"
                />
                </div>
                <div className="technology-title">Javascript</div>
            </div>
            <div className="technology-item">
                <div className="technology-icon">
                <img
                    src="images/c-logo-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="technology-icon-img"
                />
                </div>
                <div className="technology-title">C++</div>
            </div>
            <div className="technology-item">
                <div className="technology-icon">
                <img
                    src="images/java-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="technology-icon-img"
                />
                </div>
                <div className="technology-title">Java</div>
            </div>
            <div className="technology-item">
                <div className="technology-icon">
                <img
                    src="images/sql-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="technology-icon-img"
                />
                </div>
                <div className="technology-title">SQL</div>
            </div>
            <div className="technology-item">
                <div className="technology-icon">
                <img
                    src="images/html5-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="technology-icon-img"
                />
                </div>
                <div className="technology-title">HTML</div>
            </div>
            <div className="technology-item">
                <div className="technology-icon">
                <img
                    src="images/css-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="technology-icon-img"
                />
                </div>
                <div className="technology-title">CSS</div>
            </div>
            </div>
            <h1 className="technology-subheader">Frameworks</h1>
            <div className="technology-list">
            <div className="technology-item">
                <div className="technology-icon">
                <img
                    src="images/django-icon-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="technology-icon-img"
                />
                </div>
                <div className="technology-title">Django</div>
            </div>
            <div className="technology-item">
                <div className="technology-icon">
                <img
                    src="images/react-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="technology-icon-img"
                />
                </div>
                <div className="technology-title">React</div>
            </div>
            <div className="technology-item">
                <div className="technology-icon">
                <img
                    src="images/nodejs-icon-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="technology-icon-img"
                />
                </div>
                <div className="technology-title">Node.js</div>
            </div>
            </div>
            <h1 className="technology-subheader">Other Technologies</h1>
            <div className="technology-list">
            <div className="technology-item">
                <div className="technology-icon">
                <img
                    src="images/mysql-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="technology-icon-img"
                />
                </div>
                <div className="technology-title">MySQL</div>
            </div>
            <div className="technology-item">
                <div className="technology-icon">
                <img
                    src="images/amazonaws-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="technology-icon-img"
                />
                </div>
                <div className="technology-title">Amazon Web Services</div>
            </div>
            <div className="technology-item">
                <div className="technology-icon">
                <img
                    src="images/git-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="technology-icon-img"
                />
                </div>
                <div className="technology-title">Git</div>
            </div>
            <div className="technology-item">
                <div className="technology-icon">
                <img
                    src="images/docker-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="technology-icon-img"
                />
                </div>
                <div className="technology-title">Docker</div>
            </div>
            <div className="technology-item">
                <div className="technology-icon">
                <img
                    src="images/webflow-svgrepo-com.svg"
                    loading="lazy"
                    alt=""
                    className="technology-icon-img"
                />
                </div>
                <div className="technology-title">Webflow</div>
            </div>
            </div>
        </div>
        </div>
    )
};

export default AboutPage;