import LinkSVG from '../../static/images/LinkSVG.svg';
import GithubSVG from '../../static/images/GithubSVG.svg';
import jsonData from '../../static/JSON/homepage.json';
import { PROJECT_LIST } from '../../static/constants';
import { useNavigate, useLocation, useHistory } from 'react-router-dom';

// Icons/Images
import BobaRealmSVG from '../../static/images/BobaRealmThumbnailSVG.svg';
import PathfinderIcon from '../../static/images/PathfinderIcon.svg';
import HeartBakerJPG from '../../static/images/HeartBakerJPG.jpg';

export default function ProjectSection() {
    const navigate = useNavigate();
    const handleProjectItemClick = (link) => {
      console.log(link);
      navigate(link);
    }

    return (
        <div className="portfolio-project-section">
          <h1 className="portfolio-project-header">Projects</h1>

          {PROJECT_LIST && PROJECT_LIST.map((projectItem, index) => (
                <div key={index} className="portfolio-project-block">
                <div className="portfolio-project-container">
                  <div className="portfolio-project-image-div">
                    <img
                      src={projectItem.iconIndex === 0 ? BobaRealmSVG : projectItem.iconIndex === 1 ? PathfinderIcon : projectItem.iconIndex === 2 ? HeartBakerJPG : ""}
                      loading="lazy"
                      alt=""
                      className="portfolio-project-image"
                    />
                  </div>
                <div className="portfolio-project-info-div">
                  <div className="project-header-div">
                    <h3 className="project-title">{projectItem.title}</h3>
                    <div onClick={() => handleProjectItemClick(projectItem.demoLink)} className="project-header-link">
                      <div className="text-block-3">click for demo page</div>
                    </div>
                  </div>

                  {projectItem.detailList && projectItem.detailList.map((projectSubitem, subIndex) => (
                    <div key={subIndex} className="project-info-block">
                      <div className="projectlbl">{projectSubitem[0]}:</div>
                      <div className="projectlbl-descriptor">
                      {projectSubitem[1]}
                        <br />
                      </div>
                    </div>
                  ))}
                  <div className="portfolio-info-links">
                  {projectItem && projectItem.Links && projectItem.Links.map((projItem, subIndex) => (
                    <a target="_blank" key={subIndex} href={projItem.link} className="portfolio-project-btn">
                    <img
                      src={projItem.icon === "link" ? LinkSVG : GithubSVG}
                      loading="lazy"
                      alt=""
                      className="portfolio-btn-icon"
                    />
                    <div className="portfolio-btn-txt">{projItem.buttonTitle}</div>
                  </a>
                  ))}
                  </div>
                  <div className="project-status-div">
                    <div className="project-status-txt">Status: {projectItem.Status && projectItem.Status}</div>
                  </div>
                </div>
                </div>
                </div>
              ))}
        </div>
    )
}