import Navbar from '../Components/HomePageComponents/Navbar';
import { useNavigate, useLocation } from 'react-router-dom';
import { TECHNOLOGY_PAGE } from '../static/constants';
import React, { useEffect, useState, useRef } from 'react';

import AWSSVG from '../static/images/AWSSVG.svg';
import CSharpSVG from '../static/images/CSharpSVG.svg';
import VisualBasicSVG from '../static/images/VisualBasicSVG.svg';
import SqlSVG from '../static/images/SqlSVG.svg';
import JavascriptSVG from '../static/images/JavascriptSVG.svg';
import CPlusPlusSVG from '../static/images/CPlusPlusSVG.svg';

import WebflowSVG from '../static/images/WebflowSVG.svg';
import PythonSVG from '../static/images/PythonSVG.svg';
import HtmlSVG from '../static/images/HtmlSVG.svg';
import CssSVG from '../static/images/CssSVG.svg';
import JavaSVG from '../static/images/JavaSVG.svg';
import NetSVG from '../static/images/NetSVG.svg';
import DjangoSVG from '../static/images/DjangoSVG.svg';
import ReactSVG from '../static/images/ReactSVG.svg';
import NodeJsSVG from '../static/images/NodeJsSVG.svg';
import SSMSSVG from '../static/images/SSMSSVG.svg';
import MySqlSVG from '../static/images/MySqlSVG.svg';
import GitSVG from '../static/images/GitSVG.svg';
import DockerSVG from '../static/images/DockerSVG.svg';

function TechnologyPage() {
    const { state } = useLocation();
    const [currentTechnology, setCurrentTechnology] = useState('')
    const imgDictionary = {
        "C#": CSharpSVG,
        "Visual Basic": VisualBasicSVG,
        "SQL": SqlSVG,
        "Python": PythonSVG,
        "Javascript": JavascriptSVG,
        "C++": CPlusPlusSVG,
        "HTML": HtmlSVG,
        "CSS": CssSVG,
        "Java": JavaSVG,
        ".NET": NetSVG,
        "Django": DjangoSVG,
        "React.js": ReactSVG,
        "Node.js": NodeJsSVG,
        "SQL Server Management System": SSMSSVG,
        "MySQL": MySqlSVG,
        "Amazon Web Services": AWSSVG,
        "Docker": DockerSVG,
        "Git": GitSVG,
        "Webflow": WebflowSVG
      };

      const indexDictionary = {
        "C#": 0,
        "Visual Basic":1,
        "SQL": 2,
        "Python": 3,
        "Javascript": 4,
        "C++": 5,
        "HTML": 6,
        "CSS": 7,
        "Java": 8,
        ".NET": 9,
        "Django": 10,
        "React.js": 11,
        "Node.js": 12,
        "SQL Server Management System": 13,
        "MySQL": 14,
        "Amazon Web Services": 15,
        "Docker": 16,
        "Git": 17,
        "Webflow": 18
      };

      const handleChildStateChange = (techItem) => {
        console.log(techItem);
      }

      useEffect(() => {
        if(state) {
            const targetElement = document.getElementById("uxTechnologyItem" + indexDictionary[state.action]);
            if (targetElement) {
                window.scrollTo(0, 0);
                targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
      }, [state.action]);
      
    return (
        <div style={{ width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            <Navbar />

            {TECHNOLOGY_PAGE && TECHNOLOGY_PAGE.Languages && TECHNOLOGY_PAGE.Languages.map((item, index) => (
                <div id={"uxTechnologyItem" + index} key={index} className={item.Language === state.action ? "technology-view-brighten" : "technology-view-item"} style={{ borderBottom: index == TECHNOLOGY_PAGE.Languages.length - 1 ? "0px": "1px solid var(--bordercolorprimary)"}}>
                        <div className="technology-view-header">
                            <img
                                src={imgDictionary[item.Language]}
                                loading="lazy"
                                alt=""
                                className="header-icon"
                            />
                        <h1 className="header-txt">{item.Language}</h1>
                    </div>
                    <div className="technology-details">
                        <div className="technology-detail-item">
                        <h1 className="technology-view-subheader">Experience: </h1>
                        <div className="technology-view-txt">{item.Experience} years</div>
                        </div>
                        <div className="technology-detail-listview-item">
                        <h1 className="technology-view-subheader">
                            Experience Details:
                        </h1>
                        <div className="technology-view-list" style={{fontSize: "15px", fontWeight: "400", lineHeight: "18px"}}>
                            {/* <div className="technology-view-listitem">N/A</div> */}
                            {item.Description}
                        </div>
                        </div>
                    </div>
            </div>
            ))}
        </div>
    )
}

export default TechnologyPage;