import ResumeSVG from '../../static/images/ResumeSVG.svg';
import LinkedinSVG from '../../static/images/LinkedinSVG.svg';
import GithubSVG from '../../static/images/GithubSVG.svg';

export default function AppreciationSection () {


    return (
        <div className="portfolio-appreciation-section">
            <h1 className="portfolio-appreciation-header">A Sincere Thank You!</h1>
            <div className="appreciation-txt">
            You made it to the end of my portfolio. Thank you so much for taking the
            time out of your day to consider me! I am always striving to improve so
            please leave feedback if you'd like. I hope that you like what you see,
            and that we can potentially work together in the future!
            <br />
            </div>
            <div className="portfolio-footer-links">
            <a href={`/RobinNguyenResume.pdf`} download  className="portfolio-hero-center-link-div">
                <img
                src={ResumeSVG}
                loading="lazy"
                alt=""
                className="portfolio-hero-image-link"
                />
                <div className="portfolio-hero-center-link">Resume</div>
            </a>
            <a target="_blank" href="https://www.linkedin.com/in/robinnguyenk/" className="portfolio-hero-center-link-div">
                <img
                src={LinkedinSVG}
                loading="lazy"
                alt=""
                className="portfolio-hero-image-link"
                />
                <div className="portfolio-hero-center-link">LinkedIn</div>
            </a>
            <a target="_blank" href="https://github.com/RobKNguyen" className="portfolio-hero-center-link-div">
                <img
                src={GithubSVG}
                loading="lazy"
                alt=""
                className="portfolio-hero-image-link"
                />
                <div className="portfolio-hero-center-link">GitHub</div>
            </a>
            </div>
            <div className="final-msg-txt">
            Feel free to reach out to me at any of the links above!
            </div>
        </div>
    )
}