import React, { useEffect, useState, useRef } from 'react';

import ResumeSVG from '../../static/images/ResumeSVG.svg';
import LinkedinSVG from '../../static/images/LinkedinSVG.svg';
import GithubSVG from '../../static/images/GithubSVG.svg';
import RobinHeadSVG from '../../static/images/RobinHeadSVG.svg';

export default function Hero() {

      const handleDownload = () => {
        // Logic to trigger the download
        const resumeUrl = '/path/to/resume.pdf'; // Replace with the actual path to your resume file
        window.open(resumeUrl, '_blank');
      };

    return (
        <div className="portfolio-hero">
        <div className="portfolio-hero-container">
        <div className="top-left"></div>
          <div className="bottom-right"></div>
          <div className="hero-main-content">
            <div className="hero-container-div">
              <div className="hero-txt-main">
                <h1 className="hero-txt-h1">Hello, I'm Robin</h1>
                <div className="hero-descriptor">
                  Software Engineer from San Bruno, CA
                </div>
                <div className="hero-descriptor-detail">
                  I am currently on the Software Development team
                  <br />
                  at Acumen, LLC where I collaborate with other <br />
                  professionals from various teams to develop high quality
                  enterprise level product.
                </div>
              </div>
              <img
                src={RobinHeadSVG}
                loading="lazy"
                alt=""
                className="portfolio-hero-notion-style-image"
              />
            </div>
            <div className="hero-btn-links">
              <a href={`/RobinNguyenResume.pdf`} download className="portfolio-hero-link">
                <img
                  src={ResumeSVG}
                  loading="lazy"
                  alt=""
                  className="portfolio-hero-image-link"
                />
                <div className="portfolio-hero-link-txt">Resume</div>
              </a>
              <a target="_blank" href="https://www.linkedin.com/in/robinnguyenk/" className="portfolio-hero-link">
                <img
                  src={LinkedinSVG}
                  loading="lazy"
                  alt=""
                  className="portfolio-hero-image-link"
                />
                <div className="portfolio-hero-link-txt">LinkedIn</div>
              </a>
              <a target="_blank" href="https://github.com/RobKNguyen" className="portfolio-hero-link">
                <img
                  src={GithubSVG}
                  loading="lazy"
                  alt=""
                  className="portfolio-hero-img"
                />
                <div className="portfolio-hero-link-txt">GitHub</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
}