import React, { useEffect } from 'react';
import Navbar from '../Components/HomePageComponents/Navbar';
import HeroSection from '../Components/HomePageComponents/HeroSection';
import ProjectSection from '../Components/HomePageComponents/ProjectSection';
import TechnologySection from '../Components/HomePageComponents/TechnologySection';
import GetInTouchSection from '../Components/HomePageComponents/GetInTouchSection';
import AppreciationSection from '../Components/HomePageComponents/AppreciationSection';



function HomePage() {

    return (
        <div style={{ width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <Navbar />
                <HeroSection />
                <ProjectSection />
                <TechnologySection />
                <GetInTouchSection />
                <AppreciationSection />
        </div>
    )
}

export default HomePage;