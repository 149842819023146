import { useEffect } from 'react';
import { TECHNOLOGIES } from '../../static/constants.js';
import { useNavigate, useLocation, useHistory } from 'react-router-dom';

import AWSSVG from '../../static/images/AWSSVG.svg';
import CSharpSVG from '../../static/images/CSharpSVG.svg';
import VisualBasicSVG from '../../static/images/VisualBasicSVG.svg';
import SqlSVG from '../../static/images/SqlSVG.svg';
import JavascriptSVG from '../../static/images/JavascriptSVG.svg';
import CPlusPlusSVG from '../../static/images/CPlusPlusSVG.svg';

import WebflowSVG from '../../static/images/WebflowSVG.svg';
import PythonSVG from '../../static/images/PythonSVG.svg';
import HtmlSVG from '../../static/images/HtmlSVG.svg';
import CssSVG from '../../static/images/CssSVG.svg';
import JavaSVG from '../../static/images/JavaSVG.svg';
import NetSVG from '../../static/images/NetSVG.svg';
import DjangoSVG from '../../static/images/DjangoSVG.svg';
import ReactSVG from '../../static/images/ReactSVG.svg';
import NodeJsSVG from '../../static/images/NodeJsSVG.svg';
import SSMSSVG from '../../static/images/SSMSSVG.svg';
import MySqlSVG from '../../static/images/MySqlSVG.svg';
import GitSVG from '../../static/images/GitSVG.svg';


export default function TechnologySection() {
    const imgDictionary = {
      "Amazon Web Services": AWSSVG,
      "C#": CSharpSVG,
      "Visual Basic": VisualBasicSVG,
      "SQL": SqlSVG,
      "Javascript": JavascriptSVG,
      "Python": PythonSVG,
      "HTML": HtmlSVG,
      "C++": CPlusPlusSVG,
      "CSS": CssSVG,
      "Java": JavaSVG,
      ".NET": NetSVG,
      "Django": DjangoSVG,
      "React.js": ReactSVG,
      "Node.js": NodeJsSVG,
      "SQL Server Management System": SSMSSVG,
      "MySQL": MySqlSVG,
      "Git": GitSVG,
      "Webflow": WebflowSVG
    };

    const navigate = useNavigate();

    const handleTechnologyItemClick = (techItem) => {
      // console.log('Handle Tech click for: ' + techItem);
      navigate('/technology', {state: {action: techItem}});
    }

    const createGradientColor = (index, numberOfDivs) => {
      // Set a base color (starting color)
      const baseColor = [71, 200, 230]; // Red in RGB

      // Calculate a color based on the index
      const hue = (index / numberOfDivs) * 29;
      const [r, g, b] = baseColor.map((value) => Math.round(value * (index - 1 / numberOfDivs)));

      return `hsl(${hue}, 100%, 53%, ${r / 255}, ${g / 255}, ${b / 255})`;
    };

    useEffect(() => {
    }, [])

    return (
        <div className="technology-section">
    <h1 className="technology-section-title">Technologies</h1>

    {TECHNOLOGIES && TECHNOLOGIES.map((techItem, index) => (
      <div key={index}>
      <h1 className="technology-subheader">{techItem.title}</h1>
      <div className="technology-list">
      {techItem && techItem.items && techItem.items.map((techSubItem, subIndex) => (
          <div key={subIndex}
          style={{
            background: createGradientColor(subIndex, techItem.items.length),
          }}
          className="technology-item" onClick={() => handleTechnologyItemClick(techSubItem)}>
          <div className="technology-icon">
            <img
              src={imgDictionary && imgDictionary[techSubItem]}
              loading="lazy"
              alt=""
              className="technology-icon-img"
            />
          </div>
          <div className="technology-title">{techSubItem}</div>
          </div>
        ))}
        </div>
      </div>
    ))}
  </div>
    )
}