

export default function GetInTouchSection() {
    return (
        <div className="portfolio-prompt-section">
            <h1 className="portfolio-prompt-header">Get in touch!</h1>
            <div className="portfolio-contact-form-block w-form">
            <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                className="portfolio-contact-form"
                data-wf-page-id="6544546f9413edec38ba6dce"
                data-wf-element-id="2dbe843c-d760-1739-d800-13e445d2eede"
            >
                <label htmlFor="name" className="portfolio-contact-field-label">
                Email
                </label>
                <input
                type="text"
                className="portfolio-contact-text-field w-input"
                maxLength={256}
                name="name"
                data-name="Name"
                placeholder="Please enter your email."
                id="name"
                />
                <label htmlFor="field" className="portfolio-contact-field-label">
                Message
                </label>
                <textarea
                placeholder="Let me know if you would like to work with me!"
                maxLength={5000}
                id="field"
                name="field"
                data-name="Field"
                className="portfolio-contact-textarea w-input"
                defaultValue={""}
                />
                <input
                type="submit"
                defaultValue="Submit"
                data-wait="Please wait..."
                className="portfolio-contact-submit-btn w-button"
                />
            </form>
            <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
            </div>
            </div>
            <div>
            <div className="text-block-2">
                If you are willing to work together on future projects, please feel free
                to reach out and I will get back to you as soon as possible. I greatly
                appreciate your time and consideration.
            </div>
            <div className="contact-form-block w-form">
                <form
                id="email-form-2"
                name="email-form-2"
                data-name="Email Form 2"
                method="get"
                className="contract-btn-form"
                data-wf-page-id="6544546f9413edec38ba6dce"
                data-wf-element-id="5c630c7e-46f1-e4b7-edf0-cc7e751235c0"
                >
                <a href='/contact'className="portfolio-getintouch-btn">
                    <div className="portfolio-btn-txt">Let's talk!</div>
                  </a>
                </form>
                <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
                </div>
            </div>
            </div>
        </div>
    )
}