import React, { useEffect, useState, useRef } from 'react';
import jsonData from '../../static/JSON/homepage.json';
import {NAV_LEFT_TITLE, NAV_ITEMS, NAV_BUTTONS} from '../../static/constants';
import { useNavigate, useLocation, useHistory } from 'react-router-dom';

// Import SVG Images
import HamburgerSVG from '../../static/images/HamburgerSVG.svg';
import DotSVG from '../../static/images/DotSVG.svg';
import ProfessionalSVG from '../../static/images/ProfessionalSVG.svg';
import AboutSVG from '../../static/images/AboutSVG.svg';
import MazeSVG from '../../static/images/MazeSVG.svg';
import MilkTeaSVG from '../../static/images/MilkTeaSVG.svg';
import HeartBakerIconSVG from '../../static/images/HeartBakerIconSVG.svg';
import ExtracurricularSVG from '../../static/images/ExtracurricularSVG.svg';


export default function Navbar() {

  const NONE_SELECTED_INDEX = -1;
  const FIRST_INDEX = 0;
  const [currentDropdownIndex, setCurrentDropdownIndex] = useState(NONE_SELECTED_INDEX);
  const [currentSubdropdownIndex, setCurrentSubdropdownIndex] = useState(FIRST_INDEX);
  const navigate = useNavigate();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [showHamburger, setShowHamburger] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 1080) {
      setHamburgerOpen(false);
    }

    const handleLessThan1080 = () => {
      // Check if the window width is 1080 pixels
      if (window.innerWidth <= 1080 && !showHamburger) {
        // Your code to handle the event when the screen is 1080px wide
        console.log('LESS than 1080px');
        setShowHamburger(true);
      }
    };

    const handleGreaterThan1080 = () => {
      // Check if the window width is 1080 pixels
      if (window.innerWidth > 1080) {
        // Your code to handle the event when the screen is 1080px wide
        console.log('MORE than 1080px');
        setShowHamburger(false);
        setHamburgerOpen(false);
      }
    };

    window.addEventListener('resize', handleLessThan1080);
    window.addEventListener('resize', handleGreaterThan1080);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleLessThan1080);
      window.removeEventListener('resize', handleGreaterThan1080);
    };
  }, []);

  const handleHamburgerClick = e => {
    setHamburgerOpen(!hamburgerOpen)
  }

  // Event Listeners
  const handleDropdownItemMouseEnter = (index) => {
    setCurrentDropdownIndex(index);
  }

  const handleDropdownItemMouseLeave = () => {
    setCurrentDropdownIndex(NONE_SELECTED_INDEX);
  }

  const handleSubDropdownItemMouseEnter = (index) => {
    setCurrentSubdropdownIndex(index);
  }

  const handleSubDropdownItemMouseLeave = () => {
    setCurrentSubdropdownIndex(FIRST_INDEX);
  }

  const handleTechnologyClick = (navSublistItem) => {
    setHamburgerOpen(false);
    navigate('/technology', {state: {action: navSublistItem}})
  }

  const handleNavItemClick = (navigateTo, navigateState) => {
    setHamburgerOpen(false);
    navigate(navigateTo, {state: navigateState});
  }

  const handleHomeClick = () => {
    setHamburgerOpen(false);
    navigate('/');
  }

  const handleEmailButtonClick = () => {
    const emailAddress = 'robinnguyenk@gmail.com';
    const subject = `Let's talk Robin!`;
    const body = 'Hello,\n\n';

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Use a try-catch block to handle any errors
    try {
      // Use window.open instead of setting window.location.href
      window.open(mailtoLink, '_blank');
    } catch (error) {
      console.error('Error opening email client:', error);
    }
  };

  const handleLetsTalkClick = () => {
    setHamburgerOpen(false);
    console.log("Hello")
    navigate('/contact');
  }

  return (
    <>
    <div className="portfolio-nav-v2">
      <div className="nav-left">
        <div className="nav-title" onClick={() => handleHomeClick()}>{NAV_LEFT_TITLE}</div>
      </div>
      <div className="nav-links-default">

        {NAV_ITEMS && NAV_ITEMS.map((navListItem, index) => (
            <div key={index} className="nav-link-div" onMouseEnter={() => handleDropdownItemMouseEnter(index)} onMouseLeave={() => handleDropdownItemMouseLeave()}>
              <div className="nav-link">{navListItem.title}</div>
              { navListItem.type === 'singular' ? (
              <div className="nav-dropdown" style={{ display: currentDropdownIndex === index ? "flex" : "none" }}>
                { navListItem.items && navListItem.items.map((navSublistItem, subIndex) => (
                  <div key={subIndex} onClick={() => handleNavItemClick(navSublistItem.navigateTo, navSublistItem.state)} className="nav-dropdown-item">{navSublistItem.title}</div>
                ))}
              </div>) : (<div className="nav-dropdown-dualsided" onMouseLeave={handleSubDropdownItemMouseLeave} style={{ display: currentDropdownIndex === index ? "flex" : "none" }}>
                <div className="nav-dropdown-leftside">
                  {navListItem.items && navListItem.items.map((navSublistItem, subIndex) => (
                    <div key={subIndex} className="nav-category-div" onMouseEnter={() => handleSubDropdownItemMouseEnter(subIndex)}>
                      <div className="nav-category-item">{navSublistItem.title}</div><img style={{ display: currentSubdropdownIndex === subIndex ? "block" : "none" }} src={DotSVG} loading="lazy" alt="" className="nav-category-selected-icon" />
                    </div>
                  ))}
                </div>
                <div className="nav-dropdown-rightside">
                  <div className="nav-technology-container">
                    {navListItem.itemsRight[currentSubdropdownIndex] && navListItem.itemsRight[currentSubdropdownIndex].map((navSublistItem, subIndex) => (
                        <a key={subIndex} onClick={() => handleTechnologyClick(navSublistItem)} className="nav-technology-item">{navSublistItem}</a>
                    ))}
                  </div>
                </div>
              </div>) }
            </div>
        ))}
        {NAV_BUTTONS.items && NAV_BUTTONS.items.map((navListItem, index) => (
            <div onClick={() => handleLetsTalkClick()} key={index} className="nav-action-btn">{navListItem.title}</div>
        ))}
      </div>
      <div className="nav-links-hamburger">
        <a className="nav-hamburger-link-div w-inline-block"><img src={HamburgerSVG} loading="lazy" alt="" className="hamburger-icon" onClick={handleHamburgerClick} /></a>
      </div>
    </div>
          {hamburgerOpen ? 
    <div className="nav-primary-hamburger-section">
  <div className="nphs-top">
  {NAV_ITEMS && NAV_ITEMS.map((navListItem, index) => (
    <div key={index} className="nphs-top-item">
    <div className="nphs-nav-button">
      <div className="nphs-nav-button-text">{navListItem.title}</div>
      <img
        src="../images/right-thin-chevron-svgrepo-com.svg"
        loading="lazy"
        alt=""
        className="nphs-chevron-symbol"
      />
    </div>
    { navListItem.type === 'singular' ? (
      <div role="list" className="nav-subitem-list">
        { navListItem.items && navListItem.items.map((navSublistItem, subIndex) => (
          <div key={subIndex} onClick={() => handleNavItemClick(navSublistItem.navigateTo, navSublistItem.state)} className="nphst-subitem">
          <img
            src={navSublistItem.title === 'Boba Realm' ? MilkTeaSVG : navSublistItem.title === 'Pathfinding Visualizer' ? MazeSVG : navSublistItem.title === 'Heart-baker' ? HeartBakerIconSVG : navSublistItem.title === 'About me' ? AboutSVG : navSublistItem.title === 'Professional Experience' ? ProfessionalSVG : navSublistItem.title === 'Extracurriculars' ? ExtracurricularSVG : null}
            loading="lazy"
            alt=""
            className="nphs-subitem-icon"
          />
          <div className="nphs-subitem-text">{navSublistItem.title}</div>
        </div>
        ))}
      </div>

    ) : (
      
          <>
          {navListItem.itemsRight && navListItem.itemsRight.map((navSublistItem, subIndex) => (
            <div  key={subIndex} className="nav-subitem-list">
            <div className="nav-subitem-title">{ subIndex === 0 ? 'Programming Languages' : subIndex === 1 ? 'Frameworks' : 'Other'}</div>
            <div className="nav-lvl2-subitem">
              {navSublistItem && navSublistItem.map((techItem, techIndex) => (
                <div key={techIndex} onClick={() => handleTechnologyClick(techItem)} className="nav-btn-link">{techItem}</div>
              ))}
            
              
            </div>
            </div>
          ))}
          </>

    )}
    </div>
  ))}
  </div>
  <div className="nphs-bottom">
    <div className="nphs-bottom-item">
      <div className="nphs-bottom-form-block w-form">
          <div className="nphs-bottom-submit-button" onClick={() => handleLetsTalkClick()}>Let's talk!</div>
      </div>
    </div>
  </div>
</div>
: null}

    </>
  );
}